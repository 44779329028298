import { _Linkable } from 'prismic-types';
import { Link } from '../link/Link';
import s from './MegaMenu.module.scss';
import { MotionProps, motion } from 'framer-motion';
import { linkResolver } from 'prismic/linkResolver';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames/bind';
import { useUiState } from 'hooks/useUiState';

const c = classNames.bind(s);

export type MegaMenuGroupProps = {
  title: string;
  links: Array<{
    title: string;
    link: _Linkable;
  }>;
};

const group = {
  variants: {
    closed: { y: -16, opacity: 0 },
    open: { y: 0, opacity: 1 },
  },
  transition: { opacity: { duration: 0.2 }, y: { duration: 0.2 } },
} satisfies MotionProps;

export const MegaMenuGroup = ({ title, links }: MegaMenuGroupProps) => {
  const { uiState } = useUiState();
  const { currentPage } = uiState;
  const uid = uuidv4();
  const firstLink =
    links && links[0] && links[0].link && linkResolver(links[0].link);

  return (
    <motion.div
      className={s.group}
      variants={group.variants}
      initial="closed"
      key={`${title}-${uid}`}
    >
      {firstLink && (
        <Link className={s.group__title} to={firstLink}>
          {title}
        </Link>
      )}
      <ul>
        {links.map((link) => (
          <MegaMenuLinkItem
            key={`${link.title}-${uid}`}
            title={link.title}
            link={link.link}
            isActive={linkResolver(link.link) === currentPage}
          />
        ))}
      </ul>
    </motion.div>
  );
};

const MegaMenuLinkItem = ({
  title,
  link,
  isActive,
}: {
  title: string;
  link: _Linkable;
  isActive: boolean;
}) => {
  const url = linkResolver(link);
  return (
    <li className={c(s.linkItem, { isActive })}>
      <div className={s.dot}></div>
      <Link to={url}>{title}</Link>
    </li>
  );
};
